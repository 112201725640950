import * as dompack from "dompack";
import "./overlaymenu.scss";

dompack.onDomReady(() => {
  const overlayMenu = document.querySelector("#overlay-menu");
  const buttons = document.querySelectorAll(".js-toggle-mainmenu");
  const mmMenu = document.querySelector("#overlay-menu-menu");

  const submenus = document.querySelectorAll(".overlay-submenu");
  const ddTogglers = document.querySelectorAll(".overlay-dropdown-toggle");

  submenus.forEach((submenu) => {
    submenu.setAttribute(
      "style",
      "--overlay-submenu-items: " + submenu.firstChild.childElementCount
    );
  });

  const showMenu = (menu, btns) => {
    document.documentElement.classList.add("lock-scroll");
    menu.classList.add("showmenu");
    overlayMenu.style.zIndex = "99";
    btns.forEach((btn) => {
      btn.classList.add("is-active");
    });
  };
  const hideMenu = (menu, btns) => {
    document.documentElement.classList.remove("lock-scroll");
    menu.style.animation = "fadeOutToNone 0.3s ease-out";
    overlayMenu.style.zIndex = "97";
    setTimeout(() => {
      menu.style.animation = "none";
    }, 300);
    menu.classList.remove("showmenu");
    btns.forEach((btn) => {
      btn.classList.remove("is-active");
    });
  };

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      window.getComputedStyle(mmMenu).display == "none"
        ? showMenu(mmMenu, buttons)
        : hideMenu(mmMenu, buttons);
    });
  });

  ddTogglers.forEach((toggle) => {
    let active = false;

    toggle.addEventListener("click", (e) => {
      if (!active) toggle.classList.add("dropdown-active");

      if (active) toggle.classList.remove("dropdown-active");

      active = !active;
      console.log(active);
    });
  });
});
